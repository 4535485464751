<template>
    <div class="header">
        <div class="header-left">
            <button type="button" class="btn header-btn" :title="$t('back_to_home')" @click="$router.back()"
                    v-if="!hideBack">
                <i class="icon-back"></i>
            </button>
        </div>

        <slot name="center">
            <router-link to="/" class="header-logo">
                <img src="@/assets/logo_small.png" alt="Christmas Every Day"
                     srcset="@/assets/logo_small@2x.png 2x" />
            </router-link>
        </slot>
    </div>
</template>

<script>
export default {
    name: "PageHeader",
    props: {
        hideBack: {
            type: Boolean,
        }
    }
}
</script>