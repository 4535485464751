


























import { defineComponent } from "@vue/composition-api"
import events from "../data/events"
import firebase from "firebase";

export default defineComponent({
    name: "EventDetail",
    setup(props, context) {
        const eventId = context.root.$route.params.id;
        const event = events.find(i => i.id === eventId);
        const close = function () {
            context.root.$router.back();
        }

        firebase.analytics().logEvent('select_content', {
            content_type: 'event',
            content_id: eventId,
        });

        return {
            event,
            close
        }
    }
})
