
































import { defineComponent } from "@vue/composition-api"
import events from "../data/events"
import firebase from "firebase";

export default defineComponent({
    name: "Event",
    setup() {
        firebase.analytics().logEvent('page_view', {
            page_title: 'Event List',
        });

        return {
            events
        }
    }
})
