import {SetupContext} from "@vue/composition-api";
import Konva from "konva";
import Layer = Konva.Layer;
import Image = Konva.Image;
import BasePhotoEditor, {
    BasePhotoEditorOptions,
} from "@/photo_processor/BasePhotoEditor";
import {imageDataToImageElement} from "@/photo_processor/utils";
import Stage = Konva.Stage;

export type PhotoEditorMode = string | undefined;

export interface PhotoEditorOptions extends BasePhotoEditorOptions {
    context: SetupContext;
}

export default class PhotoEditor extends BasePhotoEditor {
    constructor(options: PhotoEditorOptions) {
        super(options);
    }

    async drawMaskTo(mask: ImageData, maskObj: Image) {
        if (!this.stage)
            return;

        const imgEl = await imageDataToImageElement(mask);
        maskObj.width(mask.width);
        maskObj.height(mask.height);
        maskObj.image(imgEl);

        this.stage.batchDraw();
    }

    async computeResult(): Promise<CanvasImageSource> {
        return new Promise((resolve, reject) => {
            if (!this.stage)
                return reject('stage is undefined');

            // Restore stage size
            const dim = this.originalDim!;

            const stage = (this.stage.clone({
                width: dim.width,
                height: dim.height,
                scale: {x: 1, y: 1},
                position: {x: 0, y: 0}
            }) as Stage);

            const userPhoto = stage.findOne<Image>('#userPhoto');
            const drawboardLayer = stage.findOne<Layer>('#drawboardLayer');

            if (!userPhoto) {
                return reject('Cannot find userPhoto');
            }
            if (!drawboardLayer) {
                return reject('Cannot find drawboardLayer');
            }

            const imgCanvas = userPhoto.toCanvas({});
            const imgCxt = imgCanvas.getContext('2d')!;
            imgCxt.globalCompositeOperation = 'destination-out';
            drawboardLayer.toImage({
                width: dim.width,
                height: dim.height,
                x: 0,
                y: 0,
                callback: function (img) {
                    imgCxt.drawImage(img, 0, 0);
                    resolve(imgCanvas);
                }
            })
        })
    }
}