import Plugin from "@/photo_processor/Plugin";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import Vector2d = Konva.Vector2d;
import {getCenter, getDistance, ZOOM_FACTOR_MAX, ZOOM_FACTOR_MIN, WHEEL_SCALE_DELTA} from "@/photo_processor/utils";
import BasePhotoEditor from "@/photo_processor/BasePhotoEditor";

export class Gesture extends Plugin {
    private lastCenter : Vector2d | null = null;
    private lastDist = 0;

    constructor(photoEditor: BasePhotoEditor) {
        super(photoEditor);
    }

    registerEvents(): void {
        const stage = this.stage;
        if (stage) {
            stage.on('touchmove', this.onTouchMove.bind(this));
            stage.on('touchend', this.onTouchEnd.bind(this));

            stage.on('wheel', this.onWheel.bind(this));
        }
    }

    unregisterEvents(): void {
    }

    onTouchMove(e: KonvaEventObject<TouchEvent>) {
        const touch1 : Touch | null = e.evt.touches[0];
        const touch2 : Touch | null = e.evt.touches[1];
        const stage = this.stage;

        if (!touch1 || !touch2 || !stage)
            return;

        if (stage.isDragging()) {
            stage.stopDrag();
        }

        const p1 = { x: touch1.clientX, y: touch1.clientY };
        const p2 = { x: touch2.clientX, y: touch2.clientY };

        const newCenter = getCenter(p1, p2);
        const dist = getDistance(p1, p2);

        if (!this.lastCenter) {
            this.lastCenter = newCenter;
            return;
        } else if (!this.lastDist) {
            this.lastDist = dist;
        }

        // local coordinates of center point
        const pointTo = {
            x: (newCenter.x - stage.x()) / stage.scaleX(),
            y: (newCenter.y - stage.y()) / stage.scaleX(),
        };

        const bestDim = this.photoEditor.bestDim!;
        const scale = Math.min(ZOOM_FACTOR_MAX, Math.max(bestDim.scale || ZOOM_FACTOR_MIN, stage.scaleX() * (dist / this.lastDist)));

        stage.scaleX(scale);
        stage.scaleY(scale);

        // calculate new position of the stage
        const dx = newCenter.x - this.lastCenter.x;
        const dy = newCenter.y - this.lastCenter.y;

        const imgSizeDiff = {
            x: this.photoEditor.originalDim!.width * scale - stage.width(),
            y: this.photoEditor.originalDim!.height * scale - stage.height(),
        }

        const newPos = {
            x: Math.max(-imgSizeDiff.x, Math.min(0, newCenter.x - pointTo.x * scale + dx)),
            y: Math.max(-imgSizeDiff.y, Math.min(0, newCenter.y - pointTo.y * scale + dy)),
        };

        stage.position(newPos);
        stage.batchDraw();

        this.lastDist = dist;
        this.lastCenter = newCenter;
    }

    onTouchEnd() {
        this.lastDist = 0;
        this.lastCenter = null;
    }

    onWheel(e: KonvaEventObject<WheelEvent>) {
        e.evt.preventDefault();

        const stage = this.stage;
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const scaleBy = WHEEL_SCALE_DELTA;
        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const bestDim = this.photoEditor.bestDim!;
        let newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        newScale = Math.min(ZOOM_FACTOR_MAX, Math.max(bestDim.scale || ZOOM_FACTOR_MIN, newScale));

        stage.scale({ x: newScale, y: newScale });

        // Position
        const imgSizeDiff = {
            x: this.photoEditor.originalDim!.width * newScale - stage.width(),
            y: this.photoEditor.originalDim!.height * newScale - stage.height(),
        }

        const newPos = {
            x: Math.max(-imgSizeDiff.x, Math.min(0, pointer.x - mousePointTo.x * newScale)),
            y: Math.max(-imgSizeDiff.y, Math.min(0, pointer.y - mousePointTo.y * newScale)),
        };
        stage.position(newPos);
        stage.batchDraw();
    }
}