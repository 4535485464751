import {calcBestDim} from "@/photo_processor/utils";
import Plugin from "./Plugin";
import BasePhotoEditor from "@/photo_processor/BasePhotoEditor";
import Konva from "konva";

export default class FitCanvas extends Plugin {
    protected target?: Konva.Node;

    constructor(photoEditor: BasePhotoEditor) {
        super(photoEditor);
    }

    registerEvents() {
        window.addEventListener('resize', this.fit.bind(this));
    }

    unregisterEvents() {
        window.addEventListener('resize', this.fit.bind(this));
    }

    setTarget(target: Konva.Node) {
        this.target = target;
    }

    fit() {
        if (!this.target)
            throw "Target element is not defined. Unable to fit stage";

        const containerEl = this.photoEditor.container;

        const {width, height, scale} = calcBestDim(this.target.width(), this.target.height(), containerEl.offsetWidth, containerEl.offsetHeight);
        this.photoEditor.setBestDim({width, height, scale});

        this.photoEditor.setStageSize(width, height);
        this.photoEditor.stage.scale({x: scale, y: scale});

        this.photoEditor.draw();
    }
}
