






import {defineComponent, ref} from "@vue/composition-api"

export default defineComponent({
    name: "CircleButton",
    props: {
        iconClass: String
    }
})
