


















import {
    defineComponent,
    ref,
    onMounted,
    onBeforeUnmount,
    watch,
    toRef,
    SetupContext
} from "@vue/composition-api"
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PhotoEditor from "@/photo_processor/PhotoEditor";
import Konva from "konva";
import Stage = Konva.Stage;
import Image = Konva.Image;
import Layer = Konva.Layer;
import {KonvaNodeComponent} from "@/photo_processor/BasePhotoEditor";

export interface PhotoCanvasComponent {
    computeResult() : Promise<CanvasImageSource>;
}

export default defineComponent({
    name: "PhotoCanvas",
    components: {LoadingIndicator},
    props: {
        tool: String,
        image: HTMLImageElement,
        mask: ImageData,
    },
    setup(props, context: SetupContext) {
        const loading = ref(true);

        // Reference of Component & Element
        const container = ref<HTMLElement | null>(null);
        const stage = ref<KonvaNodeComponent<Stage> | null>(null);
        const userPhoto = ref<KonvaNodeComponent<Image> | null>(null);
        const userMask = ref<KonvaNodeComponent<Image> | null>(null);
        const drawboardLayer = ref<KonvaNodeComponent<Layer> | null>(null);

        // Photo Editor
        let photoEditor : PhotoEditor;

        const computeResult = function () : Promise<CanvasImageSource> {
            return photoEditor.computeResult();
        }

        const photoEditorInit = function () {
            photoEditor = new PhotoEditor({
                context,
                stage: stage.value!.getNode(),
                container: container.value!,
                drawboard: {
                    layer: drawboardLayer.value!.getNode(),
                },
                gesture: true,
            });
            photoEditor.init();

            const photoNode = userPhoto.value!.getNode();
            photoEditor.loadImageTo(props.image as HTMLImageElement, photoNode);
            photoEditor.fitTo(photoNode);

            if (props.mask) {
                photoEditor.drawMaskTo(props.mask, userMask.value!.getNode());
            }

            loading.value = false;

            // Handle eraser and pen tool
            const toolRef = toRef(props, 'tool');
            photoEditor.changeTool(toolRef.value);
            watch(toolRef, function (nv) {
                photoEditor.changeTool(nv);
            });
        }

        onMounted(() => {
            photoEditorInit();
        });

        // Unregister events before the component is being unmounted
        onBeforeUnmount(() => {
            photoEditor.destroy();
        });

        return {
            loading,
            container, stage, userPhoto, userMask, drawboardLayer,
            computeResult,
        }
    }
})
