




































import {computed, defineComponent, Ref, toRef} from "@vue/composition-api"
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import frames from "../data/frames"

export default defineComponent({
    name: "FrameChooser",
    components: {
        Splide, SplideSlide,
    },
    props: {
        value: Number,
    },
    setup(props, context) {
        const indexRef: Ref<number | undefined> = toRef(props, 'value');
        const index = computed<number>({
            get: function () : number {
                return indexRef.value!;
            },
            set: function (v) {
                context.emit('input', v);
            }
        });

        const sliderMove = function (splide: Splide, newIndex: number) {
            index.value = newIndex;
        };
        const activeFrame = computed(function () {
            return frames[index.value];
        });
        const sliderOptions = {
            type: 'loop',
            pagination: false,
        }

        return {
            frames,
            index,
            activeFrame,
            sliderMove,
            sliderOptions
        }
    }
})
