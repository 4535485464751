import Plugin from "@/photo_processor/Plugin";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import Vector2d = Konva.Vector2d;
import {getCenter, getDistance, ZOOM_FACTOR_MAX, ZOOM_FACTOR_MIN, WHEEL_SCALE_DELTA} from "@/photo_processor/utils";
import BasePhotoEditor from "@/photo_processor/BasePhotoEditor";

export class PinchScale extends Plugin {
    private lastCenter : Vector2d | null = null;
    private lastDist = 0;

    private target? : Konva.Node;

    constructor(photoEditor: BasePhotoEditor) {
        super(photoEditor);
    }

    registerEvents(): void {
    }

    unregisterEvents(): void {
    }

    addTarget(target: Konva.Node) {
        this.target = target;
        // target.on('touchmove', this.onTouchMove.bind(this));

        target.on('dragmove', this.onTouchMove.bind(this));
        target.on('dragend', this.onTouchEnd.bind(this));

        this.stage.on('wheel', this.onWheel.bind(this));
    }

    onTouchMove(e: KonvaEventObject<TouchEvent | MouseEvent>) {
        // Ignore mouse event
        if (!(e.evt instanceof TouchEvent))
            return;

        const touch1 : Touch | null = e.evt.touches[0];
        const touch2 : Touch | null = e.evt.touches[1];

        // Ensure at least two touch points
        if (!touch1 || !touch2)
            return;

        const target = e.target;
        const stage = this.stage;

        const p1 = { x: touch1.clientX, y: touch1.clientY };
        const p2 = { x: touch2.clientX, y: touch2.clientY };

        const newCenter = getCenter(p1, p2);
        const dist = getDistance(p1, p2);

        if (!this.lastCenter) {
            this.lastCenter = newCenter;
            return;
        } else if (!this.lastDist) {
            this.lastDist = dist;
        }

        const bestDim = this.photoEditor.bestDim!;
        const scale = Math.min(ZOOM_FACTOR_MAX, Math.max(bestDim.scale || ZOOM_FACTOR_MIN, target.scaleX() * (dist / this.lastDist)));

        target.scaleX(scale);
        target.scaleY(scale);

        // local coordinates of center point
        const pointTo = {
            x: (newCenter.x - target.x()) / target.scaleX(),
            y: (newCenter.y - target.y()) / target.scaleY(),
        };

        // calculate new position of the stage
        const dx = newCenter.x - this.lastCenter.x;
        const dy = newCenter.y - this.lastCenter.y;

        const newPos = {
            x: newCenter.x - pointTo.x * scale + dx,
            y: newCenter.y - pointTo.y * scale + dy,
        };

        target.position(newPos);
        stage.batchDraw();

        this.lastDist = dist;
        this.lastCenter = newCenter;
    }

    onTouchEnd(e: KonvaEventObject<TouchEvent | MouseEvent>) {
        this.lastDist = 0;
        this.lastCenter = null;
    }

    onWheel(e: KonvaEventObject<WheelEvent>) {
        e.evt.preventDefault();

        const target = this.target;

        const stage = this.stage;
        const oldScale = target.scaleX();
        const pointer = stage.getPointerPosition();

        const scaleBy = WHEEL_SCALE_DELTA;
        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const bestDim = this.photoEditor.bestDim!;
        let newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        newScale = Math.min(ZOOM_FACTOR_MAX, Math.max(bestDim.scale || ZOOM_FACTOR_MIN, newScale));

        target.scale({ x: newScale, y: newScale });

        stage.batchDraw();
    }
}