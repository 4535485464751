import Konva from "konva";
import Stage = Konva.Stage;
import Vector2d = Konva.Vector2d;
import BasePhotoEditor from "@/photo_processor/BasePhotoEditor";

export default abstract class Plugin {
    protected constructor(protected photoEditor: BasePhotoEditor) {

    }

    abstract registerEvents() : void;

    abstract unregisterEvents() : void;

    get stage(): Stage {
        return this.photoEditor.stage;
    }

    projectPoint(pos: Vector2d): Vector2d {
        const stageScale = this.photoEditor.stageScale;
        const stagePos = this.photoEditor.stagePosition;
        if (!stageScale)
            return pos;

        return {
            x: (pos.x - stagePos.x) / stageScale.x,
            y: (pos.y - stagePos.y) / stageScale.y
        };
    }

}