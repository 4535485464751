import {ref, Ref, SetupContext, UnwrapRef} from "@vue/composition-api";
import {Component} from "vue";
import FitCanvas from "@/photo_processor/FitCanvas";
import Konva from "konva";
import Stage = Konva.Stage;
import Vector2d = Konva.Vector2d;
import ImageConfig = Konva.ImageConfig;
import {calcBestDim} from "@/photo_processor/utils";
import {Drawboard, DrawboardOptions} from "@/photo_processor/Drawboard";
import {PhotoEditorMode} from "@/photo_processor/PhotoEditor";
import Image = Konva.Image;
import {Gesture} from "@/photo_processor/Gesture";
import Util = Konva.Util;

export interface Scale {
    x: number;
    y: number;
}

export interface BestDim {
    width: number;
    height: number;
    scale: number;
}

export interface BasePhotoEditorOptions {
    context: SetupContext;
    stage: Stage;
    container: HTMLElement;
    drawboard?: DrawboardOptions | false;
    gesture?: boolean;
}

export interface KonvaNodeComponent<T> {
    getNode() : T;
}

export default abstract class BasePhotoEditor {
    protected context: SetupContext;
    protected fitCanvas: FitCanvas;
    protected drawboard?: Drawboard;

    public bestDim?: BestDim;
    public originalDim?: BestDim;

    public stage: Stage;
    public container: HTMLElement;

    protected gesture?: Gesture;

    protected constructor(options: BasePhotoEditorOptions) {
        this.context = options.context;

        this.stage = options.stage;
        this.container = options.container;

        this.fitCanvas = new FitCanvas(this);

        if (options.drawboard) {
            this.drawboard = new Drawboard(this, options.drawboard);
        }

        if (options.gesture) {
            this.gesture = new Gesture(this);
        }
    }

    init() {
        this.fitCanvas.registerEvents();

        if (this.drawboard)
            this.drawboard.registerEvents();

        if (this.gesture)
            this.gesture.registerEvents();
    }

    destroy() {
        this.fitCanvas.unregisterEvents();

        if (this.drawboard)
            this.drawboard.unregisterEvents();

        if (this.gesture)
            this.gesture.unregisterEvents();
    }

    get stageScale(): Vector2d {
        return this.stage.scale() || { x: 1, y: 1 };
    }

    get stagePosition(): Vector2d {
        return this.stage.position() || { x: 0, y: 0 };
    }

    setBestDim(config: BestDim) {
        this.bestDim = config;
    }

    loadImageTo(imgEl: CanvasImageSource, imgNode: Image) {
        imgNode.image(imgEl);

        if (imgEl instanceof HTMLImageElement) {
            imgNode.width(imgEl.naturalWidth);
            imgNode.height(imgEl.naturalHeight);
        } else if (imgEl instanceof HTMLCanvasElement) {
            imgNode.width(imgEl.width);
            imgNode.height(imgEl.height);
        }
    }

    loadImageURLTo(url: string, imgNode: Image) : Promise<void> {
        return new Promise((resolve) => {
            Util._urlToImage(url, (i: HTMLImageElement) => {
                this.loadImageTo(i, imgNode);
                resolve();
            });
        });
    }

    fitTo(element : Konva.Node) {
        const {width, height} = calcBestDim(element.width(), element.height());

        this.setStageSize(width, height);

        this.bestDim = { width, height, scale: 1 };
        this.originalDim = { width, height, scale: 1 };

        this.fitCanvas.setTarget(element);
        this.fitCanvas.fit();
    }

    alignToCenter(obj: Konva.Node) {
        const dim = this.originalDim!;
        obj.position({
            x: (dim.width - obj.width()) / 2,
            y: (dim.height - obj.height()) / 2
        });
    }

    setStageSize(width: number, height: number) {
        this.stage.width(width);
        this.stage.height(height);
    }

    draw() {
        this.stage.batchDraw();
    }

    changeTool(tool: PhotoEditorMode) {
        if (this.drawboard)
            this.drawboard.tool = tool;
    }
}