








import { defineComponent, ref } from "@vue/composition-api"

export default defineComponent({
    name: "FileChooserBtn",
    setup(props, context) {
        const inputRef = ref<HTMLInputElement | null>(null);
        const inputKey = ref(0);
        const onClick = function () {
            inputRef.value?.click();
        };

        const onInput = function () {
            // Update input control key, so that the change event will be triggered when the file is selected again
            inputKey.value++;

            // Change if any file is selected
            if (inputRef.value && inputRef.value.files && inputRef.value.files.length > 0) {
                const file = inputRef.value.files[0];

                // Trigger `selected` to pass the selected file
                if (file) {
                    context.emit('selected', file);
                }
            }
        }

        return {
            file: inputRef,
            inputKey,
            onClick,
            onInput
        }
    }
})
