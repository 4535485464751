


























import {defineComponent} from "@vue/composition-api"
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import firebase from "firebase";

export default defineComponent({
    name: 'Home',
    components: {
        LanguageSwitch
    },
    setup() {
        firebase.analytics().logEvent('page_view', {
            page_title: 'Home',
        });
    }
})
