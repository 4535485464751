import {Localizable} from "@/i18n";

interface Event {
    id: string;
    name: Localizable<string>;
    location: Localizable<string>;
    content: Localizable<string>;
    cover_photo: string;
    photo: string;
    link: Localizable<string>;
}

const events: Event[] = [
    {
        id: "1",
        name: {
            "en": "Beary Christmas Shop",
            "zh-hk": "巨型聖誕熊仔玩具店",
            "zh-cn": "巨型聖誕熊仔玩具店"
        },
        location: {
            "en": "Ocean Terminal Main Entrance & Level 3",
            "zh-hk": "海運大廈正門入口及3樓",
            "zh-cn": "海运大厦正门入口及3楼"
        },
        content: {
            "en": "<p>The staircase entrance of Ocean Terminal becomes a &ldquo;Beary Christmas Shop&rdquo;! The shop is a European style wooden house, and a number of rainbow coloured checkered windows are installed to create two amazing ambiences over day and night. During the daytime, the sunlight and the Victoria Harbour view stream into the shop through the windows. During the nighttime, when the Christmas music animation broadcasts on various LED screens, together with starry lights, a romantic lighting house is revealed.</p>\n" +
                "<p>A 3-meter-high Christmas Bear has been built outside the shop window, while there are numerous giant wooden Christmas trees, gift box pendant lights, and roly-poly shaped &ldquo;Charity Christmas Bears&rdquo; in the shop. What&rsquo;s more, the main character of the shop &ndash; 30 types of plush &ldquo;Charity Christmas Bears&rdquo; in different sizes appear in various corners, giving you a warm and cheerful festive atmosphere.</p>",
            "zh-hk": "<p>海運大廈大樓梯入口會幻化成「巨型幻彩聖誕熊仔玩具店」。玩具店是一間現代歐陸風格的木屋，由格仔櫥窗組成。日間時分，陽光從透明幻彩櫥窗映進店內，同時透出迷人的海景。夜間時分，兩邊不同位置的LED屏幕更會播放聖誕音樂動畫，配上漫天星星燈，玩具店隨即化身成浪漫小屋，締造出不一樣的晝夜感覺。</p>\n" +
                "<p>面向天星碼頭的入口櫥窗外設有一隻3米高的聖誕熊，店內外有多棵木造聖誕樹、毛毛禮物屋吊燈、以及多隻「不倒翁形」慈善熊。而玩具店的主角 &ndash; 30款大小不同的毛毛慈善聖誕熊則於不同角落出現，整個布置彌漫溫暖的節日氣氛。</p>",
            "zh-cn": "<p>海运大厦大楼梯入口会幻化成「巨型幻彩圣诞熊仔玩具店」。玩具店是一间现代欧陆风格的木屋，由格仔橱窗组成。日间时分，阳光从透明幻彩橱窗映进店内，同时透出迷人的海景。夜间时分，两边不同位置的LED屏幕更会播放圣诞音乐动画，配上漫天星星灯，玩具店随即化身成浪漫小屋，缔造出不一样的昼夜感觉。</p>\n" +
                "<p>面向天星码头的入口橱窗外设有一只3米高的圣诞熊，店内外有多棵木造圣诞树丶毛毛礼物屋吊灯丶以及多只「不倒翁形」慈善熊。而玩具店的主角 &ndash; 30款大小不同的毛毛慈善圣诞熊则於不同角落出现，整个布置弥漫温暖的节日气氛。</p>",
        },
        cover_photo: require('@/assets/events/event_1_cover.jpg'),
        photo: require('@/assets/events/event_1.jpg'),
        link: {
            "en": "http://www.harbourcity.com.hk/en/article/christmas-every-day-harbour-city/",
            "zh-hk": "http://www.harbourcity.com.hk/tc/article/christmas-every-day-harbour-city/",
            "zh-cn": "http://www.harbourcity.com.hk/sc/article/christmas-every-day-harbour-city/"
        },
    },
    {
        id: "2",
        name: {
            "en": "Christmas Lighting Garden",
            "zh-hk": "聖誕燈飾花園",
            "zh-cn": "聖誕燈飾花園"
        },
        location: {
            "en": "Ocean Terminal Deck",
            "zh-hk": "海運觀點",
            "zh-cn": "海运观点"
        },
        content: {
            "en": "<p>Ocean Terminal Deck transforms into &ldquo;Christmas Lighting Garden&rdquo;, where you can take a selfie at the &ldquo;Finland Boarding Gate&rdquo; and find your way to the illuminated seasonal garden. Over a million lightings composed of rainbow-like clovers, snowflake-shaped dandelion and seamless LED lights cover the entire garden. You can take a leisurely walk with the Charity Christmas Bears during the day and also enjoy &ldquo;Christmas Lighting &amp; Music Show&rdquo; with the amazing Hong Kong skyline as a backdrop after sunset.&nbsp;</p>\n" +
                "<p>6 tents with festive decoration have been set up on the natural lawn of Ocean Terminal Deck. There are 3 styles of tents for your selection &ndash; Cozy , Scandinavian and Cute look. What&rsquo;s more, Charity Christmas Bears are waiting to welcome you in the tents! Come with your friends, family and loved one, start the &ldquo;camp fire&rdquo; and enjoy the best camping vibes with amazing views of Victoria Harbour&nbsp; from different angles.</p>",
            "zh-hk": "<p>「海運觀點」設有「往芬蘭的登機閘口」，讓大家在海旁「登機」打卡。「海運觀點」戶外平台鋪滿「幻彩四葉草」、「雪花燈球」及LED燈海，打造成「聖誕燈飾花園」，大家可以免費在這凸顯香港的地標欣賞維港兩岸晝夜不同的醉人景致及燈飾。日間可於幻彩四葉草花園與聖誕熊拍照；而日落後更可欣賞聖誕燈光音樂表演。</p>\n" +
                "<p>另外，「海運觀點」戶外草坪設置6個換上聖誕裝飾的帳幕，帳幕內的布置分別有3大主題︰適合一家大小打卡的溫暖家居風、適合小情侶合照的北歐風，以及為小朋友或一眾少女心而設的可愛風。帳幕更放置了毛毛慈善聖誕熊，大家既可在一望無際的海景下與摯愛、好友或家人打卡，又可在帳幕內「熊」抱聖誕，一起舉行「聖誕營火會」。</p>",
            "zh-cn": "<p>「海运观点」设有「往芬兰的登机闸口」，让大家在海旁「登机」打卡。「海运观点」户外平台铺满「幻彩四叶草」丶「雪花灯球」及LED灯海，打造成「圣诞灯饰花园」，大家可以免费在这凸显香港的地标欣赏维港两岸昼夜不同的醉人景致及灯饰。日间可於幻彩四叶草花园与圣诞熊拍照；而日落後更可欣赏圣诞灯光音乐表演。</p>\n" +
                "<p>另外，「海运观点」户外草坪设置6个换上圣诞装饰的帐幕，帐幕内的布置分别有3大主题∶适合一家大小打卡的温暖家居风丶适合小情侣合照的北欧风，以及为小朋友或一众少女心而设的可爱风。帐幕更放置了毛毛慈善圣诞熊，大家既可在一望无际的海景下与挚爱丶好友或家人打卡，又可在帐幕内「熊」抱圣诞，一起举行「圣诞营火会」。</p>"
        },
        cover_photo: require('@/assets/events/event_2_cover.jpg'),
        photo: require('@/assets/events/event_2.jpg'),
        link: {
            "en": "http://www.harbourcity.com.hk/en/article/christmas-every-day-harbour-city/",
            "zh-hk": "http://www.harbourcity.com.hk/tc/article/christmas-every-day-harbour-city/",
            "zh-cn": "http://www.harbourcity.com.hk/sc/article/christmas-every-day-harbour-city/"
        },
    },
    {
        id: "3",
        name: {
            "en": "Christmas Club Performance Stage",
            "zh-hk": "聖誕學會表演舞台",
            "zh-cn": "圣诞学会表演舞台"
        },
        location: {
            "en": "Atrium II, Gateway Arcade",
            "zh-hk": "港威商場大堂II",
            "zh-cn": "海运观点"
        },
        content: {
            "en": "<p>Dutch artist Eva Cremers has designed several 3D characters for Harbour City this Christmas. They are a group of students who dress up as monsters, establish the &ldquo;Christmas Club&rdquo;, and &ldquo;perform&rdquo; and raise funds for Hong Kong Blood Cancer Fund with a great sense of humour. The &ldquo;School Hall&rdquo; at Atrium II, Gateway Arcade is their &ldquo;stage&rdquo;.</p>\n" +
                "<p>On the stage, Chairman Firry, cool kid Melty &amp; the loving Hugsy are &ldquo;performing&rdquo;; Swingy appears on stage out of nowhere and makes everyone laugh as always; Dingy &amp; Dongy, who are passionate for music, are DJing offstage; while Beardy, the cheeky kid with fake beard, is cheering up those on stage. At the same time, clumsy Lamspy gets accidentally stuck in the Christmas lights again, laughing at his own reflection on the backstage mirror. What a funny scene to see!</p>\n" +
                "<p>How can we miss Gifty for Christmas? He is holding up a television, asking every visitor to join our interactive game. You can mix and match different parts of the members&rsquo; costumes, and create your own &ldquo;Monster&rdquo;. Take a snap with your creation, download to the phone and share with everybody!</p>",
            "zh-hk": "<p>荷蘭藝術家Eva Cremers 為海港城設計多個3D小角色──一群熱愛表演的同學，穿上趣怪的怪獸服飾，特別組成「聖誕學會」，以幽默的「表演」為「香港血癌基金」籌款。</p>\n" +
                "<p>港威商場大堂II的「學校禮堂」是他們的「聖誕舞台」，會長Firry、外冷內熱的Melty及暖男Hugsy在台上「演出」；喜歡爬來爬去的Swingy也突然出現在台上娛賓；熱愛音樂的Dingy和Dongy 則在台下「打碟伴奏」，而開心果Beardy在則一旁為各為同學打氣。此時在後台裡，當懶洋洋的Cloudy在偷懶，身手笨拙的Lampsy一不小心，又給聖誕燈飾纏著，畫面相當有趣！</p>\n" +
                "<p>聖誕佳節又怎少得愛分享快樂的Gifty？他捧起小電視，邀請來賓參與互動小遊戲。大家可以把同學們各人的表演服飾隨意配搭，創造出新的「怪獸朋友」，更可即時與其留影並下載到自己的手機，與大家分享。</p>\n",
            "zh-cn": "<p>荷兰艺术家Eva Cremers 为海港城设计多个3D小角色──一群热爱表演的同学，穿上趣怪的怪兽服饰，特别组成「圣诞学会」，以幽默的「表演」为「香港血癌基金」筹款。</p>\n" +
                "<p>港威商场大堂II的「学校礼堂」是他们的「圣诞舞台」，会长Firry丶外冷内热的Melty及暖男Hugsy在台上「演出」；喜欢爬来爬去的Swingy也突然出现在台上娱宾；热爱音乐的Dingy和Dongy 则在台下「打碟伴奏」，而开心果Beardy在则一旁为各为同学打气。此时在後台里，当懒洋洋的Cloudy在偷懒，身手笨拙的Lampsy一不小心，又给圣诞灯饰缠着，画面相当有趣！</p>\n" +
                "<p>圣诞佳节又怎少得爱分享快乐的Gifty？他捧起小电视，邀请来宾参与互动小游戏。大家可以把同学们各人的表演服饰随意配搭，创造出新的「怪兽朋友」，更可即时与其留影并下载到自己的手机，与大家分享。</p>\n"
        },
        cover_photo: require('@/assets/events/event_3_cover.jpg'),
        photo: require('@/assets/events/event_3.jpg'),
        link: {
            "en": "http://www.harbourcity.com.hk/en/article/christmas-club-2020/",
            "zh-hk": "http://www.harbourcity.com.hk/tc/article/christmas-club-2020/",
            "zh-cn": "http://www.harbourcity.com.hk/sc/article/christmas-club-2020/"
        },
    },
    {
        id: "4",
        name: {
            "en": "Christmas Club Activity Room",
            "zh-hk": "聖誕學會活動室",
            "zh-cn": "圣诞学会活动室"
        },
        location: {
            "en": "Atrium, G/F,  Ocean Terminal",
            "zh-hk": "海運大廈地下大堂",
            "zh-cn": "海运大厦地下大堂"
        },
        content: {
            "en": "<p>The members have been preparing for the &ldquo;performance&rdquo; for so long. Let&rsquo;s take a tour down to Atrium, G/F, Ocean Terminal, where the &ldquo;Club&rsquo;s Activity Room&rdquo; is located, and where they practice regularly. The Members have decorated the room into a colourful space, and the notice boardis showing everyone&rsquo;s portrait and practice schedule. Chairman Firry and funnily stylish Wormy are in the Room to welcome you. Kids can also play with Wormy and hop on his the back!</p>\n" +
                "<p>Besides taking a look at the preparation of the Members, you may come to the interactive colouring zone and let your creativity flow, re-colouring the &ldquo;Monster Members&rdquo;! Here, you can paint on the black &amp; white paper with a &ldquo;Monster&rdquo; on. Once scanned, they will come onto the screen against different scenes!</p>",
            "zh-hk": "<p>各位成員早已籌備「演出」多時，海運大廈地下中庭正是他們籌備「表演」的「學會活動室」。大家把「活動室」佈置得色彩繽紛，壁報板張貼了各位成員的大頭照和練習日程表，會長和打扮鬼馬的Wormy亦在「活動室」迎接大家。小朋友們更可以坐到Wormy身上玩耍拍照！</p>\n" +
                "<p>大家除了可在此參觀成員們的準備情況，更可到互動填色區發揮自己的創意，為各位「怪獸成員」重新填色！只要在印有不同「怪獸成員」圖案的黑白色畫紙上，任意塗出不同顏色。填色後，一經掃瞄後，「小怪獸」就會在大屏幕上不同的場景出現！</p>",
            "zh-cn": "<p>各位成员早已筹备「演出」多时，海运大厦地下中庭正是他们筹备「表演」的「学会活动室」。大家把「活动室」布置得色彩缤纷，壁报板张贴了各位成员的大头照和练习日程表，会长和打扮鬼马的Wormy亦在「活动室」迎接大家。小朋友们更可以坐到Wormy身上玩耍拍照！</p>\n" +
                "<p>大家除了可在此参观成员们的准备情况，更可到互动填色区发挥自己的创意，为各位「怪兽成员」重新填色！只要在印有不同「怪兽成员」图案的黑白色画纸上，任意涂出不同颜色。填色後，一经扫瞄後，「小怪兽」就会在大屏幕上不同的场景出现！</p>"
        },
        cover_photo: require('@/assets/events/event_4_cover.jpg'),
        photo: require('@/assets/events/event_4.jpg'),
        link: {
            "en": "http://www.harbourcity.com.hk/en/article/christmas-club-2020/",
            "zh-hk": "http://www.harbourcity.com.hk/tc/article/christmas-club-2020/",
            "zh-cn": "http://www.harbourcity.com.hk/sc/article/christmas-club-2020/"
        },
    },
];

export default events;