



















import {defineComponent, ref} from "@vue/composition-api"
import Alert from "@/components/Alert.vue";
import PhotoPreviewCanvas, {PhotoPreviewCanvasComponent} from "@/components/PhotoPreviewCanvas.vue";
import {getPlayerData} from "@/PlayerData";
import firebase from "firebase";

export default defineComponent({
    name: "PositionAdjust",
    components: {PhotoPreviewCanvas, Alert},
    setup(props, context) {
        const photoCanvas = ref<PhotoPreviewCanvasComponent | null>(null);

        const playerData = getPlayerData();
        const photo = playerData?.sourcePhoto;

        const onDone = async function () {
            if (!playerData || !photoCanvas.value)
                return;

            // Compute result image
            playerData.resultImage = await photoCanvas.value.computeResult();

            // Go to position adjustment page
            await context.root.$router.push('/result');
        }

        firebase.analytics().logEvent('page_view', {
            page_title: 'Position Adjust',
        });

        return {
            photoCanvas,
            photo,
            onDone
        }
    }
})
