






















import {defineComponent, onBeforeMount} from "@vue/composition-api"
import Alert from "@/components/Alert.vue";
import {getPlayerData} from "@/PlayerData";
import firebase from "firebase";

export default defineComponent({
    name: "DetectFailure",
    components: {Alert},
    setup(props, context) {
        const playerData = getPlayerData()!;
        const back = async function () {
            await context.root.$router.back();
        }

        firebase.analytics().logEvent('page_view', {
            page_title: 'Failure',
        });

        return {
            imgSrc: playerData.sourcePhoto!.src!,
            back
        }
    }
})
