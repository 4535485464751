









import { defineComponent } from "@vue/composition-api"
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default defineComponent({
    name: "LoadingOverlay",
    components: {LoadingIndicator},
    props: {
        text: String
    },
    setup() {
        return {}
    }
})
