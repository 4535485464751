import PhotoEditor, {PhotoEditorMode} from "@/photo_processor/PhotoEditor";
import Konva from "konva";
import Line = Konva.Line;
import {KonvaEventObject} from "konva/types/Node";
import Plugin from "./Plugin";
import BasePhotoEditor from "@/photo_processor/BasePhotoEditor";
import Layer = Konva.Layer;

const STROKE_WIDTH = 20;

export interface DrawboardOptions {
    layer: Layer;
}

export class Drawboard extends Plugin {
    private isDrawing = false;
    private lastLine : Line | null = null;

    public tool: PhotoEditorMode;

    constructor(photoEditor: BasePhotoEditor, private options: DrawboardOptions) {
        super(photoEditor);
    }

    registerEvents() {
        const stage = this.photoEditor.stage;
        if (stage) {
            stage.on('mousedown touchstart', this.onTouchStart.bind(this));
            stage.on('mousemove touchmove', this.onTouchMove.bind(this));
            stage.on('mouseup touchend', this.onTouchEnd.bind(this));
        }
    }

    unregisterEvents() {

    }

    get drawLayer() : Layer {
        return this.options.layer;
    }

    onTouchStart() {
        if (!this.drawLayer || !this.stage)
            return;

        this.isDrawing = true;

        let pos = this.stage.getPointerPosition();
        if (!pos)
            return;

        const posProjected = this.projectPoint(pos);

        this.lastLine = new Line({
            stroke: '#ffffff',
            strokeWidth: STROKE_WIDTH,
            globalCompositeOperation: this.tool === 'eraser' ? 'source-over' : 'destination-out',
            points: [posProjected.x, posProjected.y],
        });
        this.drawLayer.add(this.lastLine);
    }

    onTouchMove(e: KonvaEventObject<TouchEvent | MouseEvent>) {
        if (!this.isDrawing || !this.drawLayer || !this.lastLine || !this.stage) {
            return;
        }

        if (e.evt instanceof TouchEvent && e.evt.touches.length > 1) {
            this.isDrawing = false;
            return;
        }

        const pos = this.stage.getPointerPosition();
        if (!pos)
            return;

        const posProjected = this.projectPoint(pos);
        const newPoints = this.lastLine.points().concat([posProjected.x, posProjected.y]);
        this.lastLine.points(newPoints);
        this.drawLayer.batchDraw();
    }

    onTouchEnd() {
        this.isDrawing = false;
    }
}