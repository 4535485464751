<template>
    <div class="loading-snowman">
        <div class="snowman loading-snowman-1"></div>
        <div class="snowman loading-snowman-2"></div>
    </div>
</template>

<script>
export default {
    name: "LoadingIndicator"
}
</script>