





















import { defineComponent, ref } from "@vue/composition-api"
import LoadingOverlay from "@/components/LoadingOverlay.vue"
import FrameChooser from "@/components/FrameChooser.vue";
import FileChooserBtn from "@/components/FileChooserBtn.vue";
import {getPlayerData} from "@/PlayerData";
import PhotoProcessor from "@/photo_processor/PhotoProcessor";

import firebase from "firebase/app";

export default defineComponent({
    name: "Frames",
    components: {FileChooserBtn, FrameChooser, LoadingOverlay },
    setup(props, context) {
        const processing = ref(false);
        const frameId = ref(0);

        const playerData = getPlayerData();

        // Handle chosen photo
        const onFileChosen = async function (file: File) {
            if (file.type !== 'image/jpeg') {
                alert(context.root.$t('file_format_error'));
                return;
            } else if (!playerData) {
                return;
            }

            // Display processing message overlay
            processing.value = true;

            // Read the inputted file
            const processor = new PhotoProcessor();
            playerData.sourcePhoto = await processor.loadFile(file);

            // Store selected frame ID
            playerData.frameId = frameId.value;

            // Execute recognizer
            try {
                // Store human mask
                playerData.mask = await processor.analyze();

                firebase.analytics().logEvent('select_item', {
                    content_type: 'frame',
                    content_id: frameId.value.toString(),
                });

                // Go to mask adjustment page
                await context.root.$router.push('/mask');
            } catch (e) {
                // console.log(e);
                playerData.mask = null;

                // Go to failure message page
                await context.root.$router.push('/failure');
            }
        }

        return {
            processing,
            frameId,
            onFileChosen
        }
    }
})
