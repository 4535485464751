import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import Home from '../views/Home.vue'
import Frames from '../views/Frames.vue'
import DetectFailure from '../views/DetectFailure.vue'
import MaskAdjust from '../views/MaskAdjust.vue'
import PositionAdjust from '../views/PositionAdjust.vue'
import Result from '../views/Result.vue'
import Event from '../views/Event.vue'
import Share from '../views/Share.vue'
import ViewPhoto from '../views/ViewPhoto.vue'
import EventDetail from "../views/EventDetail.vue";
import {requiresPlayerData} from "@/PlayerData";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {path: '/', name: 'Home', component: Home},
    {path: '/frames', name: 'Frames', component: Frames},
    {path: '/failure', name: 'DetectFailure', component: DetectFailure, meta: { requiresData: ['frameId', 'sourcePhoto'] }},
    {path: '/mask', name: 'MaskAdjust', component: MaskAdjust, meta: { requiresData: ['frameId', 'sourcePhoto'] }},
    {path: '/position', name: 'PositionAdjust', component: PositionAdjust, meta: { requiresData: ['frameId', 'sourcePhoto'] }},
    {path: '/result', name: 'Result', component: Result, meta: { requiresData: ['resultImage'] }},
    {
        path: '/event',
        component: Event,
        children: [
            {path: '', name: 'Event', component: { render: (h) => h('div') }},
            {path: ':id', name: 'EventDetail', component: EventDetail}
        ]
    },
    {path: '/share', name: 'Share', component: Share, meta: { requiresData: ['resultImage'] }},

    {path: '/view/:photoId', name: 'ViewPhoto', component: ViewPhoto},
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresData) {
        requiresPlayerData((router.app as any).playerData, to.meta.requiresData, next);
    }
    next();
});

export default router
