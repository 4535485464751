




































import { defineComponent, ref, computed } from "@vue/composition-api"
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import {getPlayerData} from "@/PlayerData";
import axios from "axios";
import firebase from "firebase";

interface UploadResponse {
    url: string;
    photoId: string;
}

export default defineComponent({
    name: "Share",
    components: {LoadingOverlay},
    setup() {
        const uploaded = ref<boolean>(false);
        const uploadResult = ref<UploadResponse | null>(null);
        const playerData = getPlayerData()!;

        const photoUrl = computed(() => uploadResult.value ? uploadResult.value.url : playerData.resultImage);

        const doUpload = async function (dataUrl: string) {
            const res = await axios.post((process.env.VUE_APP_API_BASE_URL || '/api')+'/upload', {
                file: dataUrl
            });

            uploadResult.value = res.data as UploadResponse;
            uploaded.value = true;
        }

        if (playerData.resultImage) {
            doUpload(playerData.resultImage);
        }

        const shareUrl = computed(() => {
            if (!uploadResult.value)
                return null;

            const url = window.location.origin + '/view/' + uploadResult.value.photoId;
            const encodedUrl = encodeURI(url);

            const emailBody = encodeURI('Merry Christmas! Thanks for your participation!\n\nHere is your composite photo in front of Christmas decorations at Harbour City.\n\n'+encodedUrl);

            return {
                facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
                twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}`,
                weibo: `http://service.weibo.com/share/share.php?url=${encodedUrl}`,
                wechat: `http://www.addtoany.com/add_to/wechat?linkurl=${encodedUrl}`,
                email: `mailto:?subject=${encodeURI('Christmas Every Day @ Harbour City 2020')}&body=${emailBody}`
            }
        });

        const onShareClicked = function (e: string) {
            firebase.analytics().logEvent('share', {
                method: e,
                content_type: 'image',
                content_id: uploadResult.value!.photoId
            });
        }

        firebase.analytics().logEvent('page_view', {
            page_title: 'Share',
        });

        return {
            uploaded,
            photoUrl,
            shareUrl,
            onShareClicked
        }
    }
})
