











import {defineComponent} from "@vue/composition-api"

export default defineComponent({
    name: "Snowfall",
    setup() {
        const rnd = (min: number, max: number): number => (Math.random() * (max - min) + min);

        return {
            rnd
        }
    }
})
