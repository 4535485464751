

























import {defineComponent, ref} from "@vue/composition-api"
import ZoomBar from "@/components/ZoomBar.vue";
import PhotoCanvas, {PhotoCanvasComponent} from "@/components/PhotoCanvas.vue";
import CircleButton from "@/components/CircleButton.vue";
import {getPlayerData} from "@/PlayerData";
import firebase from "firebase";

export default defineComponent({
    name: "MaskAdjust",
    components: {ZoomBar, CircleButton, PhotoCanvas},
    setup(props, context) {
        const zoomLv = ref<number | null>(1);
        const activeTool = ref<string | null>('eraser');
        const switchTool = function (tool: string ){
            activeTool.value = tool;
        };
        const photoCanvas = ref<PhotoCanvasComponent | null>(null);

        const playerData = getPlayerData();
        const photo = playerData?.sourcePhoto;
        const bodyMask = playerData?.mask;

        const onDone = async function () {
            if (!playerData || !photoCanvas.value)
                return;

            // Compute result image
            playerData.maskedPhoto = await photoCanvas.value.computeResult();

            // Go to position adjustment page
            await context.root.$router.push('/position');
        }

        firebase.analytics().logEvent('page_view', {
            page_title: 'Mask Adjust',
        });

        return {
            zoomLv,
            activeTool,
            switchTool,
            photo,
            photoCanvas,
            bodyMask,
            onDone
        }
    }
})
