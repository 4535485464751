import "./assets/main.scss"
import Vue from "vue"

import "./api"
import {firestoreInit} from "./firebase"

firestoreInit();

// Install Composition API
import VueCompositionAPI from "@vue/composition-api"

Vue.use(VueCompositionAPI)

// Install Konva Plugin
import VueKonva from 'vue-konva'

Vue.use(VueKonva)

import App from "./App.vue"
import router from "./router"
import i18n from "./i18n"

import PortalVue from 'portal-vue'
import {newPlayerData} from "@/PlayerData";

Vue.use(PortalVue)

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    data() {
        return {
            playerData: newPlayerData()
        }
    },
    render: h => h(App),
}).$mount('#app')
