import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enMessage from './en.json'
import zhHkMessage from './zh-hk.json'
import zhCnMessage from './zh-cn.json'

Vue.use(VueI18n)

const i18n: VueI18n = new VueI18n({
    locale: 'en',
    messages: {
        "en": enMessage,
        "zh-hk": zhHkMessage,
        "zh-cn": zhCnMessage,
    }
})

type Lang = 'en'|'zh-hk'|'zh-cn';
export type Localizable<T> = Record<Lang, T>;
type LocalizableTranslator<T> = (localizable: Localizable<T>) => T;

declare interface ComponentInstance {
    $i18n: VueI18n;
}
declare class VueI18nExtend extends VueI18n {
    localize: LocalizableTranslator<any>;
}

(i18n as VueI18nExtend).localize = function<T> (localizable: Localizable<T>): T {
    return localizable[this.locale as Lang];
}


export default i18n;