














import {defineComponent, provide, reactive} from "@vue/composition-api"
import Snowfall from "@/components/Snowfall.vue";
import {newPlayerData, PLAYER_DATA_KEY} from "@/PlayerData";
import PageHeader from "@/components/PageHeader.vue";

function handleAppHeight() {
    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight);
    appHeight();
}

export default defineComponent({
    name: 'App',
    components: {PageHeader, Snowfall},
    setup(props, context) {
        handleAppHeight();
        provide(PLAYER_DATA_KEY, (context.root as any).playerData);
    }
});
