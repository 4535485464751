import {calcBestDim} from "@/photo_processor/utils";

async function dataURLToImage(dataURL: string) : Promise<HTMLImageElement> {
    return fileToImage(dataURL);
}

async function fileToImage(file: File | Blob | MediaSource | string) : Promise<HTMLImageElement> {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
            const dim = calcBestDim(img.naturalWidth, img.naturalHeight, 960, 960);

            const canvas = document.createElement("canvas");
            canvas.width = dim.width;
            canvas.height = dim.height;

            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0, dim.width, dim.height);

            const resultImg = new Image();
            resultImg.onload = function () {
                resolve(resultImg);
            }
            canvas.toBlob(function (blob) {
                resultImg.src = URL.createObjectURL(blob);
            }, 'image/jpeg', 0.9);

            // Release source file object URL
            if (img.src.startsWith('blob:')) {
                URL.revokeObjectURL(img.src);
            }
        }
        img.src = typeof file === 'string' ? file : URL.createObjectURL(file);
    });
}

export default class PhotoProcessor {
    private sourceImg? : CanvasImageSource;

    async loadFile(file: File) : Promise<HTMLImageElement> {
        this.sourceImg = await fileToImage(file);
        return this.sourceImg;
    }

    async analyze() : Promise<ImageData> {
        if (!this.sourceImg)
            throw 'You must load source image before calling analyze()';

        const bodyPix = await import('@tensorflow-models/body-pix');

        const net = await bodyPix.load({
            architecture: 'MobileNetV1',
            outputStride: 16,
            multiplier: 0.75,
            quantBytes: 2
        });

        const segmentation = await net.segmentPerson(this.sourceImg as HTMLImageElement, {
            flipHorizontal: false,
            internalResolution: 'full',
            segmentationThreshold: 0.7,
        });

        if (!segmentation.data.some(i => i != 0))
            throw "Nobody is detected";

        return bodyPix.toMask(segmentation,
            {r: 255, g: 255, b: 255, a: 0},
            {r: 255, g: 255, b: 255, a: 255},
            false);
    }
}