


















import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {defineComponent, onBeforeUnmount, onMounted, ref, toRef, watch} from "@vue/composition-api";
import frames from "@/data/frames";
import {getPlayerData} from "@/PlayerData";
import PhotoPreview from "@/photo_processor/PhotoPreview";
import Konva from "konva";
import Stage = Konva.Stage;
import Image = Konva.Image;
import {KonvaNodeComponent} from "@/photo_processor/BasePhotoEditor";

export interface PhotoPreviewCanvasComponent {
    computeResult() : Promise<string>;
}

export default defineComponent({
    name: "PhotoPreviewCanvas",
    components: {LoadingIndicator},
    props: {
        image: HTMLImageElement,
    },
    setup(props, context) {
        const loading = ref(true);

        // Reference of Component & Element
        const container = ref<HTMLElement | null>(null);
        const stage = ref<KonvaNodeComponent<Stage> | null>(null);
        const bgPhoto = ref<KonvaNodeComponent<Image> | null>(null);
        const userPhoto = ref<KonvaNodeComponent<Image> | null>(null);

        // Photo Editor
        let photoEditor : PhotoPreview;

        const playerData = getPlayerData()!;

        // Load Background Photo
        const loadBgPhoto = async function () {
            const frameId = playerData.frameId!;
            const frame = frames[frameId].photo;
            const node = bgPhoto.value!.getNode();
            await photoEditor.loadImageURLTo(frame, node);
            photoEditor.fitTo(node);
        }

        // Load Masked User Photo
        const loadMaskedPhoto = async function () {
            if (!playerData.maskedPhoto)
                return;

            const node = userPhoto.value!.getNode();
            photoEditor.loadImageTo(playerData.maskedPhoto, node);
            photoEditor.alignToCenter(node);
            photoEditor.setScaleTarget(node);
            node.setDraggable(true);
        }

        const photoEditorInit = async function () {
            photoEditor = new PhotoPreview({
                context,
                stage: stage.value!.getNode(),
                container: container.value!,
            });
            photoEditor.init();

            await loadBgPhoto();
            await loadMaskedPhoto();
            loading.value = false;
        }

        const computeResult = function () : Promise<string> {
            return photoEditor.computeResult();
        }

        onMounted(() => {
            photoEditorInit();
        });

        // Unregister events before the component is being unmounted
        onBeforeUnmount(() => {
            photoEditor.destroy();
        });

        return {
            loading,
            container, stage, bgPhoto, userPhoto,
            computeResult,
        }
    }
})
