import Konva from "konva";
import Vector2d = Konva.Vector2d;

export const MAX_WIDTH = 960;
export const MAX_HEIGHT = 960;

export const WHEEL_SCALE_DELTA = 1.03;

export interface ImageDimensionConfig {
    width: number;
    height: number;
    scale: number;
}

export function calcBestDim(elW: number, elH: number, maxW: number = MAX_WIDTH, maxH: number = MAX_HEIGHT) : ImageDimensionConfig {
    const elR = elW / elH;

    let bestW, bestH, elS;
    if (elW >= elH) {
        // Landscape or Square
        bestW = Math.min(maxW, elW);
        bestH = Math.round(bestW / elR);
        elS = bestW / elW;
    } else {
        // Portrait
        bestH = Math.min(maxH, elH);
        bestW = Math.round(bestH * elR);
        elS = bestH / elH;
    }

    return {
        width: bestW,
        height: bestH,
        scale: elS
    }
}

export const ZOOM_LEVEL_MIN = 1;
export const ZOOM_LEVEL_MAX = 100;
export const ZOOM_FACTOR_MIN = 1;
export const ZOOM_FACTOR_MAX = 2;

export function calcZoomFactor(lv: number) : number {
    return (lv - ZOOM_LEVEL_MIN) * (ZOOM_FACTOR_MAX - ZOOM_FACTOR_MIN) / (ZOOM_LEVEL_MAX - ZOOM_LEVEL_MIN) + ZOOM_FACTOR_MIN;
}

export function getCenter(p1: Vector2d, p2: Vector2d) : Vector2d {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

export function getDistance(p1: Vector2d, p2: Vector2d) : number {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

export function loadImageFromURL(url: string) : Promise<HTMLImageElement> {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = async () => {
            resolve(img);
        };
    });
}

export async function imageDataToImageElement(data: ImageData) : Promise<HTMLImageElement> {
    const canvas = document.createElement('canvas');
    canvas.width = data.width;
    canvas.height = data.height;

    const ctx = canvas.getContext('2d')!;
    ctx.putImageData(data, 0, 0);

    return new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
            resolve(img);
        };
        img.src = canvas.toDataURL('png');
    });
}