import {Localizable} from "@/i18n";

export interface Frame {
    name: Localizable<string>;
    location: Localizable<string>;
    photo: string;
}

const frames: Frame[] = [
    {
        name: {
            "en": "Beary Christmas Shop",
            "zh-hk": "巨型聖誕熊仔玩具店",
            "zh-cn": "巨型圣诞熊仔玩具店",
        },
        location: {
            "en": "Ocean Terminal Main Entrance & Level 3",
            "zh-hk": "海運大廈正門入口及3樓",
            "zh-cn": "海运大厦正门入口及3楼",
        },
        photo: require('@/assets/frames/1.jpg'),
    },
    {
        name: {
            "en": "Beary Christmas Shop",
            "zh-hk": "巨型聖誕熊仔玩具店",
            "zh-cn": "巨型圣诞熊仔玩具店",
        },
        location: {
            "en": "Ocean Terminal Main Entrance & Level 3",
            "zh-hk": "海運大廈正門入口及3樓",
            "zh-cn": "海运大厦正门入口及3楼",
        },
        photo: require('@/assets/frames/2.jpg'),
    },
    {
        name: {
            "en": "Beary Christmas Shop",
            "zh-hk": "巨型聖誕熊仔玩具店",
            "zh-cn": "巨型圣诞熊仔玩具店",
        },
        location: {
            "en": "Ocean Terminal Main Entrance & Level 3",
            "zh-hk": "海運大廈正門入口及3樓",
            "zh-cn": "海运大厦正门入口及3楼",
        },
        photo: require('@/assets/frames/3.jpg'),
    },
    {
        name: {
            "en": "Christmas Lighting Garden",
            "zh-hk": "聖誕燈飾花園",
            "zh-cn": "圣诞灯饰花园",
        },
        location: {
            "en": "Ocean Terminal Deck",
            "zh-hk": "海運觀點",
            "zh-cn": "海运观点",
        },
        photo: require('@/assets/frames/4.jpg'),
    },
    {
        name: {
            "en": "Christmas Lighting Garden",
            "zh-hk": "聖誕燈飾花園",
            "zh-cn": "圣诞灯饰花园",
        },
        location: {
            "en": "Ocean Terminal Deck",
            "zh-hk": "海運觀點",
            "zh-cn": "海运观点",
        },
        photo: require('@/assets/frames/5.jpg'),
    },
    {
        name: {
            "en": "Christmas Lighting Garden",
            "zh-hk": "聖誕燈飾花園",
            "zh-cn": "圣诞灯饰花园",
        },
        location: {
            "en": "Ocean Terminal Deck",
            "zh-hk": "海運觀點",
            "zh-cn": "海运观点",
        },
        photo: require('@/assets/frames/6.jpg'),
    },
    {
        name: {
            "en": "Hong Kong to Finland Boarding Gate",
            "zh-hk": "香港往芬蘭登機閘口",
            "zh-cn": "香港往芬兰登机闸口",
        },
        location: {
            "en": "Ocean Terminal Deck",
            "zh-hk": "海運觀點",
            "zh-cn": "海运观点",
        },
        photo: require('@/assets/frames/7.jpg'),
    },
    {
        name: {
            "en": "Christmas Camp Fire",
            "zh-hk": "聖誕營火會",
            "zh-cn": "圣诞营火会",
        },
        location: {
            "en": "Ocean Terminal Deck",
            "zh-hk": "海運觀點",
            "zh-cn": "海运观点",
        },
        photo: require('@/assets/frames/8.jpg'),
    },
    {
        name: {
            "en": "Christmas Club Performance Stage",
            "zh-hk": "聖誕學會表演舞台",
            "zh-cn": "圣诞学会表演舞台",
        },
        location: {
            "en": "Atrium II, Gateway Arcade",
            "zh-hk": "港威商場大堂II",
            "zh-cn": "港威商场大堂II",
        },
        photo: require('@/assets/frames/9.jpg'),
    },
    {
        name: {
            "en": "Christmas Club Performance Stage",
            "zh-hk": "聖誕學會表演舞台",
            "zh-cn": "圣诞学会表演舞台",
        },
        location: {
            "en": "Atrium II, Gateway Arcade",
            "zh-hk": "港威商場大堂II",
            "zh-cn": "港威商场大堂II",
        },
        photo: require('@/assets/frames/10.jpg'),
    },
    {
        name: {
            "en": "Christmas Club's Activity Room",
            "zh-hk": "聖誕學會活動室",
            "zh-cn": "圣诞学会活动室",
        },
        location: {
            "en": "Atrium, G/F, Ocean Terminal",
            "zh-hk": "海運大廈地下大堂",
            "zh-cn": "海运大厦地下大堂",
        },
        photo: require('@/assets/frames/11.jpg'),
    },
    {
        name: {
            "en": "Christmas Club's Activity Room",
            "zh-hk": "聖誕學會活動室",
            "zh-cn": "圣诞学会活动室",
        },
        location: {
            "en": "Atrium, G/F, Ocean Terminal",
            "zh-hk": "海運大廈地下大堂",
            "zh-cn": "海运大厦地下大堂",
        },
        photo: require('@/assets/frames/12.jpg'),
    },
];

export default frames;