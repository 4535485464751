




















import {defineComponent, ref} from "@vue/composition-api"
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import axios from "axios";
import PageHeader from "@/components/PageHeader.vue";
import firebase from "firebase";

interface GetPhotoResponse {
    url: string;
}

export default defineComponent({
    name: "ViewPhoto",
    components: {PageHeader, LoadingOverlay},
    setup(props, context) {
        const photoUrl = ref<string | null>(null);
        const photoId = context.root.$route.params.photoId;

        const getPhoto = async function (id: string) {
            const res = await axios.get((process.env.VUE_APP_API_BASE_URL || '/api')+'/get', {
                params: {
                    id
                }
            });
            const data = res.data as GetPhotoResponse;
            photoUrl.value = data.url;
        }
        getPhoto(photoId);

        firebase.analytics().logEvent('page_view', {
            page_title: 'View Photo',
            page_path: context.root.$route.fullPath,
        });

        return {
            photoUrl
        }
    }
});
