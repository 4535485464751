











import {defineComponent} from "@vue/composition-api"

export default defineComponent({
    name: "LanguageSwitch",
    setup(props, context) {
        const switchLocale = function (newLocale: string) {
            context.root.$i18n.locale = newLocale;
        }

        return {
            switchLocale,
            label: {
                "en": "EN",
                "zh-hk": "繁",
                "zh-cn": "简",
            }
        };
    }
});
