import {inject} from "@vue/composition-api";
import {NavigationGuardNext} from "vue-router";

export const PLAYER_DATA_KEY = 'playerData';

export interface PlayerData {
    frameId: number | null;
    sourcePhoto: HTMLImageElement | null;
    mask: ImageData | null;
    maskedPhoto: CanvasImageSource | null;
    resultImage: string | null;
}

export function newPlayerData() : PlayerData {
    return {
        frameId: null,
        sourcePhoto: null,
        mask: null,
        maskedPhoto: null,
        resultImage: null,
    }
}

export function getPlayerData() : PlayerData | undefined {
    return inject<PlayerData>(PLAYER_DATA_KEY);
}

export function requiresPlayerData(data: PlayerData, keys: (keyof PlayerData)[], next: NavigationGuardNext<Vue>) {
    if (keys.length === 0)
        return;
    if (!data)
        return next('/');

    if (!keys.every(i => (typeof data[i] != undefined && data[i] != null)))
        return next('/');
}