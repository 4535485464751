import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyANIwV5IBFRByDiHJJZR3AuNmXjnxiMRVw",
    authDomain: "hcxmas2020.firebaseapp.com",
    databaseURL: "https://hcxmas2020.firebaseio.com",
    projectId: "hcxmas2020",
    storageBucket: "hcxmas2020.appspot.com",
    messagingSenderId: "192841690055",
    appId: "1:192841690055:web:e1e7e8a775359a773e47e2",
    measurementId: "G-MVEJ44DDER"
};

export function firestoreInit() {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}