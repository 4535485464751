import {SetupContext} from "@vue/composition-api";
import BasePhotoEditor, {BasePhotoEditorOptions} from "@/photo_processor/BasePhotoEditor";
import {PinchScale} from "@/photo_processor/PinchScale";
import Konva from "konva";
import Stage = Konva.Stage;

export interface PhotoPreviewOptions extends BasePhotoEditorOptions {
    context: SetupContext;
}


export default class PhotoPreview extends BasePhotoEditor {
    protected pinchScale: PinchScale;

    constructor(options: PhotoPreviewOptions) {
        super(options);

        this.pinchScale = new PinchScale(this);
    }

    init() {
        super.init();
        this.pinchScale.registerEvents();
    }

    destroy() {
        super.destroy();
        this.pinchScale.unregisterEvents();
    }

    setScaleTarget(target: Konva.Node) {
        this.pinchScale.addTarget(target);
    }

    async computeResult() : Promise<string> {
        return new Promise((resolve, reject) => {
            if (!this.stage)
                return reject('stage is undefined');

            // Clone the stage in original dimension and convert to object URL
            const dim = this.originalDim!;

            const canvas = (this.stage.clone({
                width: dim.width,
                height: dim.height,
                scale: {x: 1, y: 1},
                position: {x: 0, y: 0}
            }) as Stage).toCanvas({});
            resolve(canvas.toDataURL('image/jpeg', 0.8));
        });
    }
}