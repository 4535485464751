
















import { defineComponent } from "@vue/composition-api"
import {getPlayerData} from "@/PlayerData";
import firebase from "firebase";

export default defineComponent({
    name: "Result",
    setup() {
        const playerData = getPlayerData()!;

        firebase.analytics().logEvent('page_view', {
            page_title: 'Result',
        });

        return {
            resultImage: playerData.resultImage
        }
    }
})
