
















import {computed, defineComponent} from "@vue/composition-api"

export default defineComponent({
    name: "ZoomBar",
    props: {
        value: Number,
    },
    setup(props, context) {
        const zoomLv = computed<number>({
            get: function (): number {
                return props.value as number;
            },
            set: function (v: number | string) {
                context.emit('input', typeof v === 'string' ? parseInt(v) : v);
            }
        })

        const zoomOut = function () {
            zoomLv.value = Math.max(zoomLv.value - 1, 1);
            console.log(zoomLv);
        };
        const zoomIn = function () {
            zoomLv.value = Math.min(zoomLv.value + 1, 100);
        }

        return {
            zoomOut, zoomIn, zoomLv
        }
    }
})
